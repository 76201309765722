import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { AppContext } from "./components/util/AppContext";
import "react-day-picker/lib/style.css";
import "glider-js/glider.min.css";
import "./App.css";

import Header from "./components/header/Header";
import HomePage from "./components/home/HomePage";
import Footer from "./components/footer/Footer";
import Home from "./components/view/Home";

class App extends Component {
  constructor(props) {
    super(props);

    this.modifyContext = (attrib, value) => {
      this.setState({
        [attrib]: value
      });
    };

    this.state = {
      searchConds: {},
      searchResults: [],
      createHomeForm: {},
      authenticated: false,
      authentication: {},
      modifyContext: this.modifyContext
    };
  }

  render() {
    return (
      <div className="App">
        <AppContext.Provider value={this.state}>
          <Header />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/home" component={Home} />
            <Route path="/about" component={HomePage} />
            <Route path="/faq" component={HomePage} />
            <Route path="/rent" component={HomePage} />
            <Route path="/" component={HomePage} />
          </Switch>
          <Footer />
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
