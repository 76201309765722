import React from "react";

export const AppContext = React.createContext({
	searchConds: null,
	searchResults: null,
	createHomeForm: null,
	authenticated: false,
	authentication: null,
	modifyContext: () => {}
});
