import React, { Component } from "react";
import { AppContext } from "../util/AppContext";
import { Link } from "react-router-dom";
import { getStates, getBeds, Selector, getConditions } from "../util/formUtils";

export default class HomePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			page: 1,
			homes: [],
			noResults: true,
			hasSearched: false
		};
	}

	componentDidMount() {
		this.setState({
			homes: this.context.searchResults,
			noResults: this.context.searchResults.length === 0,
			hasSearched: this.context.searchResults.length !== 0
		});
		this.fetchHomes();
	}

	setPage = i => {
		this.setState({
			page: i
		});
	};

	fetchHomes = e => {
		fetch("http://needlodging.com/api/homes/readList.php?" + getConditions(this.context.searchConds))
			.then(response => {
				return response.json();
			})
			.then(jsonData => {
				this.setState({
					homes: jsonData,
					noResults: jsonData.length === 0,
					hasSearched: true,
					page: 1
				});
				this.context.modifyContext("searchResults", jsonData);
			})
			.catch(error => {
				console.error(error);
			});
	};

	render() {
		const { homes, page, noResults, hasSearched } = this.state;
		var numPages = Math.ceil(homes.length / 10.0);
		if (!hasSearched) {
			return (
				<React.Fragment>
					<Featured />
					<SearchBar fetcher={this.fetchHomes} />
				</React.Fragment>
			);
		} else if (noResults) {
			return (
				<React.Fragment>
					<Featured />
					<SearchBar fetcher={this.fetchHomes} />
					<div className="noResults">No Homes Found</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<Featured />
					<SearchBar fetcher={this.fetchHomes} />
					<Pagination numPages={numPages} curPage={page} setPage={this.setPage} />
					<div className="flex-row-container wrap end">
						{homes.slice((page - 1) * 10, page * 10).map(home => (
							<HomeCard home={home} key={home.name} />
						))}
					</div>
					<Pagination numPages={numPages} curPage={page} setPage={this.setPage} />
				</React.Fragment>
			);
		}
	}
}
HomePage.contextType = AppContext;

class Featured extends Component {
	render() {
		return (
			<Link to={""} className="flex-row-container tempHome hasCorner">
				<label className="corner">Sequim, WA</label>
			</Link>
		);
	}
}

class SearchBar extends Component {
	componentDidMount() {
		this.setState({
			state: this.context.searchConds.state,
			bedroom: this.context.searchConds.bedroom,
			bathroom: this.context.searchConds.bathroom,
			minPrice: this.context.searchConds.minPrice,
			maxPrice: this.context.searchConds.maxPrice
		});
	}

	handleInputChange = event => {
		this.setState(
			{
				[event.target.name]: event.target.value
			},
			() => this.context.modifyContext("searchConds", this.state)
		);
	};

	render() {
		const { searchConds } = this.context;
		return (
			<div className="flex-row-container wrap justify-between end">
				<div className="searchField">
					<label>State:</label>
					<Selector options={getStates()} name="state" value={searchConds.state || ""} onChange={this.handleInputChange} />
				</div>
				<div className="searchField">
					<label>Bedrooms:</label>
					<Selector options={getBeds()} name="bedroom" value={searchConds.bedroom || ""} onChange={this.handleInputChange} />
				</div>
				<div className="searchField">
					<label>Min Price:</label>
					<input type="text" placeholder="Min" name="minPrice" value={searchConds.minPrice || ""} onChange={this.handleInputChange} />
				</div>
				<div className="searchField">
					<label>Max Price:</label>
					<input type="text" placeholder="Max" name="maxPrice" value={searchConds.maxPrice || ""} onChange={this.handleInputChange} />
				</div>
				<div className="searchField">
					<button onClick={this.props.fetcher}>Search</button>
				</div>
			</div>
		);
	}
}
SearchBar.contextType = AppContext;

export class HomeCard extends Component {
	render() {
		const { thumbnail, ID, city, bedroom, price } = this.props.home;
		const thumbnailURL = thumbnail && thumbnail.slice(0, 3) !== "htt" && thumbnail.slice(0, 3) !== "www" ? "/pics/" + thumbnail : thumbnail;
		return (
			<Link to={{ pathname: "/home/" + ID, state: { home: ID } }}>
				<div className="homeCard hasCorner">
					<img className="cardImage" src={thumbnailURL} alt="" />
					<label className="corner">
						{city}, WA | {bedroom} Bed | ${price}
					</label>
				</div>
			</Link>
		);
	}
}

class Pagination extends Component {
	genPageNumbers() {
		const { curPage, numPages } = this.props;
		const maxPerSide = 3;
		let i = 1;
		if (curPage <= maxPerSide + 1) {
			i = 1;
		} else if (curPage >= numPages - maxPerSide) {
			i = numPages - maxPerSide * 2;
		} else {
			i = curPage - maxPerSide;
		}
		let end = Math.min(i + maxPerSide * 2, numPages);
		return Array.from(Array(end - i + 1), (value, index) => index + i);
	}

	render() {
		const { curPage, numPages, setPage } = this.props;
		return (
			<div className="flex-row-container">
				<button className="pagination" disabled={curPage <= 1} onClick={() => setPage(curPage - 1)}>
					❮
				</button>
				{this.genPageNumbers().map(i => (
					<button key={i} className={curPage === i ? "pagination active" : "pagination"} onClick={() => setPage(i)}>
						{i}
					</button>
				))}
				<button className="pagination" disabled={curPage >= numPages} onClick={() => setPage(curPage + 1)}>
					❯
				</button>
			</div>
		);
	}
}
