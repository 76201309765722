import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Header extends Component {
	render() {
		return (
			<div className="flex-row-container headerBar justify-between">
				<div className="headerMenu">
					<NavLink to="/">Needlodging.com</NavLink>
				</div>
				<div className="headerMenu flex-row-container">
					<NavLink to="/about">About</NavLink>
					<NavLink to="/faq">FAQ</NavLink>
				</div>
			</div>
		);
	}
}
