import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
	changeTheme = () => {
		var x = document.getElementsByTagName("body")[0];
		x.className = x.className === "lightTheme" ? "darkTheme" : "lightTheme";
	};

	render() {
		return (
			<div className="footer">
				<div className="footerColumn">
					<Link to="/">Find Your Vacation</Link>
					<Link to="/featured">Featured Homes</Link>
					<Link to="/about">About Us</Link>
				</div>
				<div className="footerColumn">
					<div onClick={this.changeTheme}>Dark/Light</div>
					<span>Copyright 2001-2020</span>
					<span>NeedLodging LLC</span>
				</div>
				<div className="footerColumn">
					<img src="/penguineer.png" alt="penguineer" />
				</div>
			</div>
		);
	}
}
