import React, { Component } from "react";
import { AppContext } from "../util/AppContext";
import DayPicker, { DateUtils } from "react-day-picker";
import Glider from "react-glider";

export default class RentPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			home: {},
			reservations: {},
			galleryPics: {},
			reviews: {},
			from: null,
			to: null,
		};
	}

	componentDidMount() {
		fetch("http://needlodging.com/api/homes/readHome.php?id=" + this.props.location.state.home)
			.then((response) => {
				return response.json();
			})
			.then((jsonData) => {
				this.setState({
					home: jsonData.home,
					reservations: jsonData.reservations,
					galleryPics: jsonData.pictures,
					reviews: jsonData.reviews,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	onDateChanges = (From, To) => {
		this.setState({
			from: From,
			to: To,
		});
	};

	render() {
		const { home, from, to, reservations, galleryPics, reviews } = this.state;
		return (
			<React.Fragment>
				<Gallery pics={galleryPics} />
				<HomeDescription desc={home.description} />
				<CalenderSection res={reservations} price={home.price} dateChange={this.onDateChanges} />
				<ReservationSection from={from} to={to} home={home} />
				<Reviews reviews={reviews} />
			</React.Fragment>
		);
	}
}

class CalenderSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nights: 0,
			from: null,
			to: null,
			enteredTo: null,
		};
	}

	isSelectingFirstDay = (from, to, day) => {
		const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
		const isRangeSelected = from && to;
		return !from || isBeforeFirstDay || isRangeSelected;
	};

	handleDayClick = (day, modifiers = {}) => {
		const { from, to } = this.state;
		if (modifiers.disabled) {
			return;
		}
		if (from && to && day >= from && day <= to) {
			this.setState({
				from: null,
				to: null,
				enteredTo: null,
			});
			return;
		}
		if (this.isSelectingFirstDay(from, to, day)) {
			this.setState({
				from: day,
				to: null,
				enteredTo: null,
			});
			this.props.dateChange(day, null);
		} else {
			this.setState({
				to: day,
				enteredTo: day,
			});
			this.props.dateChange(this.state.from, day);
		}
	};

	handleDayMouseEnter = (day) => {
		const { from, to } = this.state;
		if (!this.isSelectingFirstDay(from, to, day)) {
			this.setState({
				enteredTo: day,
			});
		}
	};

	render() {
		const { from, enteredTo } = this.state;
		const { res } = this.props;
		const closingarr = res.length > 0 ? res.map((reserv) => new Date(reserv.depart.replace(/-/g, "/"))) : [];
		const openingarr = res.length > 0 ? res.map((reserv) => new Date(reserv.arrive.replace(/-/g, "/"))) : [];
		const reservedarr =
			res.length > 0
				? res.map((reserv) => ({
						after: new Date(reserv.arrive.replace(/-/g, "/")),
						before: new Date(reserv.depart.replace(/-/g, "/")),
				  }))
				: [];
		const modifiers = {
			start: from,
			end: enteredTo,
			closing: closingarr,
			opening: openingarr,
			reserved: reservedarr,
		};
		//disable days after start of next reservation
		const afterNextRes = from ? new Date(Math.min(res.map((reserv) => reserv.arrive))) : null;
		const disabledDays = [...reservedarr, { before: new Date() }, { after: new Date() }]; //should be afterNextRes, temp disable for covid
		const selectedDays = [from, { from, to: enteredTo }];
		return (
			<div className="flex-row-container">
				<DayPicker
					className="Range"
					numberOfMonths={3}
					fromMonth={from}
					selectedDays={selectedDays}
					disabledDays={disabledDays}
					modifiers={modifiers}
					onDayClick={this.handleDayClick}
					onDayMouseEnter={this.handleDayMouseEnter}
				/>
			</div>
		);
	}
}

class ReservationSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nights: 0,
			Sent: false,
			Success: false,
			Msg: "",
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.to !== prevProps.to) {
			this.setState({
				nights: Math.ceil((this.props.to - this.props.from) / 86400000),
				Sent: false,
			});
		}
	}

	makeBooking = () => {
		if (!this.context.authenticated) {
			this.setState({ Msg: "You must login to make a reservation" });
			return;
		}
		this.setState({ Sent: true, Success: false, Msg: "Loading" });
		fetch(
			"http://needlodging.com/api/homes/makeReservation.php?user_ID=" +
				this.context.authentication.user_ID +
				"&arrive=" +
				this.props.from.toISOString().slice(0, 10) +
				"&depart=" +
				this.props.to.toISOString().slice(0, 10) +
				"&home_ID=" +
				this.props.home.homeID
		)
			.then((response) => {
				return response.json();
			})
			.then((jsonData) => {
				this.setState({ resSuccess: jsonData.ok, Msg: jsonData });
			})
			.catch((error) => {
				this.setState({ Msg: error.message });
				console.error(error);
			});
	};

	render() {
		const { to, from } = this.props;
		const { name, city, state, bedroom, bathroom, price } = this.props.home;
		return (
			<div className="flex-row-container resSection">
				<div className="flex-column-container resSectionColumn">
					<div>{name}</div>
					<div>
						{city}, {state}
					</div>
					<div>Bedrooms: {bedroom}</div>
					<div>Bathrooms: {bathroom}</div>
					<div>Nightly: ${price}</div>
				</div>
				<div className="flex-column-container resSectionColumn">
					<div>Arrival Date: {from ? from.toLocaleDateString() : "Please Select"}</div>
					<div>Departure Date: {to ? to.toLocaleDateString() : "Please Select"}</div>
					<div>Nights: {to ? this.state.nights : "0"}</div>
					<div>Price: ${to ? this.state.nights * price : "0"}</div>
					{this.state.Msg}
				</div>
				<div className="flex-column-container resSectionColumn">
					<button disabled={/*this.state.Sent || !from || !to*/ /*should be 'Book Now'*/ true} onClick={this.makeBooking}>
						Bookings Temporarily Disabled
					</button>
				</div>
			</div>
		);
	}
}
ReservationSection.contextType = AppContext;

class HomeDescription extends Component {
	render() {
		return <div className="description">{this.props.desc}</div>;
	}
}

class Gallery extends Component {
	render() {
		return this.props.pics.length > 0 ? (
			<Glider
				slidesToShow={1}
				draggable={true}
				hasDots={true}
				dragVelocity={1.4}
				responsive={[
					{
						breakpoint: 601,
						settings: {
							slidesToShow: 1.3,
						},
					},
				]}
			>
				{this.props.pics.map((pic) => (
					<div>
						<img key={pic.link} src={pic.link} alt="" />
					</div>
				))}
			</Glider>
		) : null;
	}
}

class Reviews extends Component {
	render() {
		const { reviews } = this.props;
		return reviews.length > 0 ? (
			<div className="description">
				{reviews.map((review) => (
					<div className="review" key={review.review_ID}>
						<div className="reviewDate">
							<span>
								{new Date(review.review_date.replace(/-/g, "/")).toLocaleDateString("en-US", {
									month: "long",
									year: "numeric",
								})}
							</span>
						</div>
						<div className="reviewText">{review.review}</div>
					</div>
				))}
			</div>
		) : null;
	}
}
