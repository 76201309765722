import React, { Component } from "react";

export function getConditions(conds) {
	return Object.keys(conds ? conds : {})
		.filter(k => conds[k] && conds[k] !== "" && conds[k] !== "--")
		.map(k => encodeURIComponent(k) + "=" + encodeURIComponent(conds[k]))
		.join("&");
}

export function getBeds() {
	return ["--", "0", "1", "2", "3", "4", "5", "6+"];
}

export function getBaths() {
	return ["--", "0", "1", "2", "3", "4+"];
}

export class Selector extends Component {
	render() {
		return (
			<select name={this.props.name} onChange={this.props.onChange} value={this.props.value}>
				{this.props.options.map(option => (
					<option value={option} key={option}>
						{option}
					</option>
				))}
			</select>
		);
	}
}

export function getStates() {
	return [
		"--",
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming"
	];
}
